<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/payment`" title="Payment" :columns="columns"
                routerpath="/accounting/addpayment" :formOptions="formOptions" :edit="edit" :add="add"
                :canDelete="canDelete" :viewFields="viewFields">
                <template #customField="{ props }">
                  <span v-if="props.column.field === 'ledgername'">
                    {{ props.row.ledger ? props.row.ledger : "" }}
                  </span>
                  <span v-else-if="props.column.field === 'suppliername'">
                    {{ props.row.supplier ? props.row.supplier : "" }}
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search ID",
        //   },
        // },
        {
          label: "Voucher Type",
          field: "vouchertype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Voucher Type",
          },
        },
        {
          label: "Payment Date",
          field: "paymentdate",
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Payment Date",
          },
        },
        {
          label: "Transaction Type",
          field: "transactiontype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Transaction Type",
          },
        },
        {
          label: "Company Name",
          field: "company",
          searchField: "companyname",
          type: "dropdown",
          url: "getCompany",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Company Name",
          },
        },
        {
          label: "Receiver Name",
          field: "supplier",
          searchField: "suppliername",
          type: "dropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Ledger Name",
          },
        },
        {
          label: "Bank/Cash",
          field: "bankcash",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bank/Cash",
          },
        },
        {
          label: "Ledger Name",
          field: "ledger",
          searchField: "ledgername",
          type: "dropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Ledger Name",
          },
        },
        {
          label: "AC No",
          field: "accountno",
          filterOptions: {
            enabled: true,
            placeholder: "Search AC No",
          },
        },
        {
          label: "Bank Name",
          field: "bankname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bank Name",
          },
        },
        {
          label: "Cheque No",
          field: "chequeno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Cheque No",
          },
        },
        {
          label: "Cheque Date",
          field: "chequedate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Cheque Date",
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search Remarks Type",
          },
        },
        {
          label: "Amount",
          field: "totalamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Amount Type",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",

          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: "fullname",
          url: "getUsers",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      viewFields: [
        {
          label: "Voucher Type",
          field: "vouchertype",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Payment Date",
          field: "paymentdate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Transaction type",
          field: "transactiontype",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Company Name",
          field: "companyname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Supplier Name",
          field: "suppliername",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Bank/Cash",
          field: "bankcash",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Ledger Name",
          field: "ledgername",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Account No",
          field: "accountno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "IFSC Code",
          field: "ifsccode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Bank Name",
          field: "bankname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Branch Name",
          field: "branchname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Cheque No",
          field: "chequeno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Cheque Date",
          field: "chequedate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Remarks",
          field: "remarks",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Total Amount",
          field: "totalamount",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Attachment1",
          field: "attachment1",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Attachment2",
          field: "attachment2",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Attachment3",
          field: "attachment3",
          type: "attachment",
          class: "col-md-4",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Payment" : "Add Payment"}`,
        submitRouterPath: "/accounting/payment",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/payment`,
        getEditValue: `${baseApi}/getpaymentById`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Payment Date",
            name: "paymentdate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select Payment date",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Transaction type",
            name: "transactiontype",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "transactiontype",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: [
              { name: "Cash" },
              { name: "Cheque/DD" },
              { name: "e-Fund Transfer" },
              { name: "NEFT" },
              { name: "IMPS" },
              { name: "RTGS" },
              { name: "IFTO" },
              { name: "Other" },
            ],
          },
          {
            label: "Company Name",
            name: "companyname",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "companyname",
            options: [],
            required: true,
          },
          {
            label: "Supplier Name",
            name: "suppliername",
            value: "",
            type: "text",
            placeholder: "Enter Supplier Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Ledger Balance",
            name: "ledgerbalance",
            value: "",
            type: "number",
            placeholder: "Enter Ledger Balance",
            class: "col-md-6",
            required: true,
          },
          {
            label: "IFSC Code",
            name: "ifsccode",
            value: "",
            type: "text",
            placeholder: "Enter IFSC Code",
            class: "col-md-6",
            required: true,
          },
          {
            label: "AC NO",
            name: "acno",
            value: "",
            type: "number",
            placeholder: "Enter Account Number",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Bank/Cash",
            name: "bankcash",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "bankcash",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: [{ name: "Bank" }, { name: "Cash" }, { name: "Other" }],
          },
          {
            label: "Ledger",
            name: "ledger",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "ledger",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: [],
          },
          {
            label: "Bank Name",
            name: "bankname",
            value: "",
            type: "text",
            placeholder: "Enter Bank Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Branch Name",
            name: "branchname",
            value: "",
            type: "text",
            placeholder: "Enter Branch Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Cheque Date",
            name: "chequedate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select Cheque date",
            class: "col-md-6",
            required: true,
          },
          {
            label: "UTR No",
            name: "utrno",
            value: "",
            type: "number",
            placeholder: "Enter UTR No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Remark",
            name: "remarks",
            value: "",
            type: "text",
            placeholder: "Enter Remarks",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Total Amount",
            name: "totalamount",
            value: "",
            type: "number",
            placeholder: "Enter Total Amount",
            class: "col-md-6",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Barter Amount",
            name: "barteramount",
            value: "",
            type: "number",
            placeholder: "Enter Barter Amount",
            class: "col-md-6",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "TDS Category",
            name: "tdscategory",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "tdscategory",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: [],
          },
          {
            label: "TDS Amount",
            name: "tdsamount",
            value: "",
            type: "number",
            placeholder: "Enter TDS Amount",
            class: "col-md-6",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Voucher Type",
            name: "vouchertype",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "vouchertype",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: [
              { name: "Other Payments" },
              { name: "Purchaser for Advance Payment to the Vendor" },
              { name: "Advance for Row Land" },
              { name: "TDS" },
              { name: "Payment to Supplier" },
              { name: "Commission Payment" },
              { name: "Contractual Payment" },
              { name: "Income Tax" },
            ],
          },
          {
            label: "Attechment 1",
            name: "att1",
            value: "",
            type: "fileinput",
            placeholder: "select Attechment 1",
            class: "col-md-6",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Attechment 2",
            name: "att2",
            value: "",
            type: "fileinput",
            placeholder: "select Attechment 2",
            class: "col-md-6",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Attechment 3",
            name: "att3",
            value: "",
            type: "fileinput",
            placeholder: "select Attechment 3",
            class: "col-md-6",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
          {
            label: "Attechment 4",
            name: "att4",
            value: "",
            type: "fileinput",
            placeholder: "select Attechment 4",
            class: "col-md-6",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
        ],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },

  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Payment") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
